<template>
  <div class="container mt-5">
    <h4>Edit your profile</h4>
    <br />
    <v-form v-model="valid" class="mb-4">
      <v-flex xs2>
        <img
          class="mb-3"
          :src="avatarUrl"
          height="150"
          v-if="avatarFile || avatarUrl"
        />
        <image-uploader
          id="avatarInput"
          accept="image/*"
          :preview="false"
          capture="environment"
          :maxWidth="900"
          :debug="1"
          doNotResize="['gif', 'svg']"
          :autoRotate="true"
          outputFormat="file"
          @input="selectImage"
        >
          <label for="avatarInput" slot="upload-label">
            <div class="v-btn mb-3 pick-file-button">
              <v-icon left dark>mdi-camera</v-icon>Upload photo
            </div>
          </label>
        </image-uploader>
      </v-flex>

      <v-text-field
        v-model="fullName"
        :error-messages="messages.fullName"
        @keyup="validate()"
        required
      >
        <template slot="label">
          Full Name
          <span aria-hidden="true">*</span>
        </template>
      </v-text-field>

      <v-textarea
        label="Description"
        placeholder="A brief description of yourself..."
        v-model="description"
      ></v-textarea>

      <v-btn
        large
        :disabled="submitIsDisabled"
        @click="submit"
        block
        color="primary"
        class="mb-3"
        >Save Profile</v-btn
      >
      <!-- <div aria-hidden="true">{{$t('message.theAsterixFieldsAreRequired')}}</div>
      -->
    </v-form>
  </div>
</template>

<style>
#avatarInput {
  display: none;
}
.v-btn.pick-file-button {
  padding: 10px;
  background: rgb(223, 221, 221);
  box-shadow: 2px 2px 4px 2px #999;
  max-width: 300px;
}
</style>

<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

//import ROUTER_URLS from "../../constants/urls/router";
import ImageUploader from 'vue-image-upload-resize';

export default {
  components: {
    ImageUploader,
  },
  created() {
    this.$getProfile().then(() => {
      this.setupModel();
    });
  },
  data() {
    return {
      valid: false,
      fullName: '',
      description: '',
      avatarName: '',
      avatarUrl: '',
      avatarFile: '',
      fileList: [],
      submitIsDisabled: false,
      messages: {
        fullName: '',
        avatarUrl: '',
        description: '',
      },
    };
  },

  methods: {
    selectImage: function(file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        this.avatarName = file.name;
        this.avatarUrl = fr.result;
        this.avatarFile = file;

        this.fileList.push({
          name: this.avatarName,
          fieldName: 'avatar',
          url: this.avatarUrl,
          file: this.avatarFile,
        });
      });
    },

    setupModel() {
      if (this.$profile) {
        this.fullName = this.$profile.full_name;
        this.avatarUrl = this.$profile.avatar;
        this.description = this.$profile.description;
      }
    },

    validate() {
      this.messages.fullName = '';

      this.valid = true;

      if (!this.fullName) {
        this.messages.fullName = 'Full name is required';
        this.valid = false;
      }
    },

    submit() {
      this.validate();

      if (this.valid) {
        this.submitIsDisabled = true;

        const payload = {};
        const user_id = this.$store.getters['auth/getUserId'];

        payload['full_name'] = this.fullName;
        payload['description'] = this.description;

        const store = this.$store;
        store
          .dispatch('profile/submitProfileAction', {
            user_id,
            payload,
            fileList: this.fileList,
          })
          .then(response => {
            this.submitIsDisabled = false;
            if (response.data.success == true) {
              store.dispatch('profile/submitProfileActionSuccess');
            } else {
              store.dispatch(
                'profile/submitProfileActionFailure',
                response.data,
              );
            }
          })
          //eslint-disable-next-line
          .catch(error => {
            this.submitIsDisabled = false;
            store.dispatch(
              'profile/submitProfileActionFailure',
              error,
            );
          });
      }
    },
  },
};
</script>
