<template>
  <div class="container">
    <v-form v-model="valid" class="mb-10">
      <v-text-field
        v-if="step===1"
        label="Username"
        v-model="form['username']"
        type="text"
        :error-messages="messages['username']"
        @keyup="validate()"
        required="true"
      ></v-text-field>

      <v-text-field
        v-if="step===1"
        label="Email"
        v-model="form['email']"
        type="email"
        :error-messages="messages['email']"
        @keyup="validate()"
        required="true"
      ></v-text-field>

      <v-text-field
        v-if="step===2"
        label="Verification token"
        v-model="form['code']"
        type="text"
        :error-messages="messages['code']"
        @keyup="validate()"
        required="true"
      ></v-text-field>

      <v-btn>Submit</v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      valid: false,
      form: {},
      messages: {}
    };
  },
  computed: {
    step() {
      return this.$route.params.step;
    }
  },
  methods: {
    validate() {
      this.messages = {};
      this.valid = true;

      const FIELD_REQUIRED = "This field is required";

      if (!this.form["username"]) {
        this.messages["username"] = FIELD_REQUIRED;
        this.valid = false;
      }
      if (!this.form["email"]) {
        this.messages["email"] = FIELD_REQUIRED;
        this.valid = false;
      }
      if (!this.form["code"]) {
        this.messages["code"] = FIELD_REQUIRED;
        this.valid = false;
      }
    },
    submit() {
      this.validate();
      if (this.valid) {
        // TODO: Next ticket will be for submit logic
      }
    }
  }
};
</script>